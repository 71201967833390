<template>
  <div :class="`inline-block rounded-md font-body font-medium ring-1 ring-inset ${color} text-sm px-2 py-1`">
    <slot>{{ label }}</slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Badge',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    variant: {
      type: String,
      default: 'default',
      validator: (variant) =>
        ['default', 'success', 'danger', 'warning', 'secondary', 'tertiary', 'info'].includes(variant),
      required: false
    }
  },
  computed: {
    color() {
      switch (this.variant) {
        case 'default':
          return 'bg-gray-50 text-gray-700 ring-gray-500 ring-opacity-10';
        case 'success':
          return 'bg-green-50 text-green-700 ring-green-500 ring-opacity-20';
        case 'danger':
          return 'bg-red-50 text-red-700 ring-red-500 ring-opacity-10';
        case 'warning':
          return 'bg-yellow-50 text-yellow-800 ring-yellow-500 ring-opacity-20';
        case 'secondary':
          return 'bg-blue-50 text-blue-700 ring-blue-500 ring-opacity-10';
        case 'tertiary':
          return 'bg-indigo-50 text-indigo-700 ring-indigo-500 ring-opacity-10';
        case 'info':
          return 'bg-purple-50 text-purple-600 ring-purple-500 ring-opacity-10';
        default:
          return 'bg-gray-50 text-gray-700 ring-gray-500 ring-opacity-10';
      }
    }
  }
};
</script>
