<template>
  <div class="container">
    <div class="px-4">
      <h1>All Exports</h1>
      <Alert :variant="alertVariant" v-if="alertMessage">{{ alertMessage }}</Alert>
    </div>
    <MNPOrderPrinting v-if="canViewMNPOrderPrinting" />
    <div class="overflow-x-hidden px-4 mt-10">
      <div class="table-overflow">
        <GenericTable
          :columns="columns"
          :data="data"
          :actions="true"
          :loading="loading"
          :pagination="pagination"
          :totalName="totalName"
          stacked
          @updateCurrentPage="updateCurrentPage"
          @sort="sort"
        >
          <template #jobId="{ row }">
            <span v-b-tooltip.hover="row.uuid" class="dashline">
              {{ formatUuid(row.uuid) }}
            </span>
          </template>
          <template #createdAt="{ row }">
            <DateTooltip :date="row.createdAt" />
          </template>
          <template #completedAt="{ row }">
            <DateTooltip :date="row.completedAt" />
          </template>
          <template #status="{ row }">
            <Badge variant="success" label="Completed" v-if="row.status === 'completed'" />
            <Badge variant="secondary" label="In Progress" v-else-if="row.status === 'in-progress'" />
            <Badge variant="default" label="Pending" v-else-if="row.status === 'pending'" />
            <Badge variant="danger" label="Failed" v-else-if="row.status === 'failed'" />
            <Badge variant="danger" :label="row.status" v-else />
          </template>
          <template #service="{ row }">
            <p>{{ row.service.charAt(0).toUpperCase() + row.service.slice(1) }}</p>
          </template>
          <template #download-button="{ row }">
            <BaseButton
              v-if="row.status === 'completed'"
              variant="success-outline"
              icon="arrow-down-to-line"
              @click.native="downloadExport(row.fileUrl, row.reportName)"
            >
              Download
            </BaseButton> </template
          >\
          <template #delete-button="{ row }">
            <GenericModal v-if="row.deleted === false || row.failedReason">
              <template #openButton="{ openModal }">
                <div>
                  <BaseButton variant="danger-outline" icon="trash-can" @click.native="openModal"> Delete </BaseButton>
                </div>
              </template>
              <template #header>
                <div class="text-lg rb-modal__title">Delete Export</div>
              </template>
              <template>
                <div class="p-4">
                  <p>Are you sure you want to delete this export?</p>
                </div>
              </template>
              <template #footer="{ closeModal }">
                <BaseButton @click.native="closeModal" class="mr-2" aria-label="Close modal"> Cancel </BaseButton>
                <BaseButton @click.native="deleteExport(row.uuid)" variant="danger"> Delete </BaseButton>
              </template>
            </GenericModal>
          </template>
        </GenericTable>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import ExportJobService from '@/lib/export-job-service-v2.js';
import Alert from '@/components/ui/Alert.vue';
import GenericTable from '@/components/GenericTable.vue';
import DateTooltip from '@/components/DateTooltip.vue';
import Badge from '@/components/rbComponents/Badge.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import GenericModal from '@/components/modals/GenericModal.vue';
import MNPOrderPrinting from '@/components/reports/MNPOrderPrinting.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  name: 'Exports',
  components: {
    Alert,
    Badge,
    GenericTable,
    DateTooltip,
    BaseButton,
    GenericModal,
    MNPOrderPrinting
  },
  async mounted() {
    this.getExports();
    this.canViewMNPOrderPrinting = await unleashFeatureEnabled(UnleashKeys.ViewMNPOrderPrinting);
  },
  data() {
    return {
      columns: [
        {
          name: 'jobId',
          label: 'Job ID',
          classes: 'w-auto'
        },
        {
          name: 'createdByName',
          label: 'Created by',
          classes: 'w-auto'
        },
        {
          name: 'createdAt',
          label: 'Created At',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'reportName',
          label: 'Type',
          classes: 'w-auto'
        },
        {
          name: 'completedAt',
          label: 'Completed At',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Status',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'failedReason',
          label: 'Message',
          classes: 'w-auto'
        },
        {
          name: 'download-button',
          classes: 'w-auto'
        },
        {
          name: 'delete-button',
          classes: 'w-auto'
        }
      ],
      data: [],
      search: null,
      sortBy: 'createdAt',
      sortDir: 'desc',
      pagination: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        sortBy: 'createdAt',
        sortDir: 'desc'
      },
      alertMessage: null,
      alertVariant: 'red',
      totalName: 'Exports',
      loading: true,
      error: null,
      canViewMNPOrderPrinting: false
    };
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    async updateSearch(search) {
      this.search = search;
      await this.updateCurrentPage(0);
      await this.getExports();
    },
    async getExports() {
      try {
        this.loading = true;
        const sessionUser = await getAuth().sessionUser();
        const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

        const params = {
          organizationId,
          search: this.search,
          page: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          sortDir: this.sortDir,
          sortBy: this.sortBy
        };

        const { data, pagination } = await ExportJobService.listExportJobs(params);
        this.pagination = pagination;
        this.data = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertMessage = `Failed to load Exports: ${error.message}`;
        this.alertVariant = 'red';
        return [];
      }
    },
    extractBucketAndKey(url) {
      const urlObj = new URL(url);
      const bucketName = urlObj.hostname.split('.')[0];
      const key = urlObj.pathname.substring(1);
      return { bucketName, key };
    },
    async getPresignedUrl(url) {
      const sessionUser = await getAuth().sessionUser();
      const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

      const { bucketName, key } = this.extractBucketAndKey(url);

      const body = {
        bucketName,
        key,
        organizationId
      };

      return await ExportJobService.downloadExportCsv(body);
    },
    downloadExportedFile(preSignedUrl, reportName) {
      const downloadDate = new Date().toISOString().split('T')[0];
      const link = document.createElement('a');
      link.href = preSignedUrl.url;
      link.setAttribute('download', `${reportName}-${downloadDate}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadExport(url, reportName) {
      try {
        const preSignedUrl = await this.getPresignedUrl(url);
        this.downloadExportedFile(preSignedUrl, reportName);
      } catch (error) {
        this.alertMessage = `Failed to download export: ${error.message}`;
        this.alertVariant = 'red';
      }
    },
    async updateCurrentPage(page) {
      this.pagination.currentPage = page;
      await this.getExports();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getExports();
    },
    async deleteExport(uuid) {
      try {
        await ExportJobService.updateExportJob(uuid, { deleted: true });
        await this.getExports();
      } catch (error) {
        this.alertMessage = `Failed to delete export: ${error.message}`;
        this.alertVariant = 'red';
      }
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
