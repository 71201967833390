<template>
  <div class="col-span-12">
    <div class="flex justify-center mt-3">
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <ul class="flex list-none">
          <PaginationItem
            screenReaderTitle="First"
            iconName="chevrons-left"
            roundEdges="left"
            @click.native="updateActivePage(0)"
          />
          <PaginationItem
            screenReaderTitle="Previous"
            iconName="chevron-left"
            :class="{ 'opacity-50 cursor-not-allowed': activePage === 0 }"
            @click.native="updateActivePage(activePage - 1)"
          />

          <PaginationItem v-if="showBeforeDots" :interactive="false">
            <span class="cursor-default">...</span>
          </PaginationItem>
          <PaginationItem
            v-for="page in totalPages"
            :key="page"
            :class="{ 'border-green-500 bg-green-50 text-green-600 z-10': activePage === page }"
            @click.native="updateActivePage(page)"
          >
            <span>{{ page + 1 }}</span>
          </PaginationItem>
          <PaginationItem v-if="showAfterDots" :interactive="false">
            <span class="cursor-default">...</span>
          </PaginationItem>

          <PaginationItem
            screenReaderTitle="Next"
            iconName="chevron-right"
            :class="{ 'opacity-50 cursor-not-allowed': activePage === lastPage }"
            @click.native="updateActivePage(activePage + 1)"
          />
          <PaginationItem
            v-if="total"
            screenReaderTitle="Last"
            iconName="chevrons-right"
            roundEdges="right"
            @click.native="updateActivePage(pages - 1)"
          />
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import PaginationItem from '@/components/ui/PaginationItem';
import { debounce } from 'lodash';

export default {
  props: {
    pages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: false
    }
  },
  components: {
    PaginationItem
  },
  data() {
    return {
      activePage: 0,
      maxVisiblePages: 3
    };
  },
  watch: {
    activePage() {
      this.getTotalPages();
    },
    currentPage() {
      this.updateActivePage(this.currentPage, false);
    }
  },
  computed: {
    totalPages() {
      return this.getTotalPages();
    },
    showBeforeDots() {
      return this.activePage > this.maxVisiblePages - 1;
    },
    showAfterDots() {
      return this.activePage + 1 < this.pages - 2;
    }
  },
  methods: {
    updateActivePage: debounce(function (page, emit = true) {
      const maxPages = this.pages - 1;

      if (page < 0) {
        this.activePage = 0;
      } else if (page >= maxPages && this.total) {
        this.activePage = maxPages;
      } else {
        this.activePage = page;
      }

      if (emit) {
        this.$emit('updateCurrentPage', this.activePage);
      }
    }, 500),
    getTotalPages() {
      const displayPageAmount = Math.min(this.maxVisiblePages, this.pages);

      // I found this code on stack overflow as a common way to achieve this type of pagination
      let firstPage = this.activePage - Math.floor(displayPageAmount / 2);
      firstPage = Math.max(firstPage, 0);
      firstPage = Math.min(firstPage, this.pages - displayPageAmount);

      return [...Array(displayPageAmount)].map((key, index) => index + firstPage);
    }
  }
};
</script>
